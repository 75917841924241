import { useIsAuthenticated } from '@azure/msal-react';
import { useEffect } from 'react';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { SignOutButton } from '../Authentication/SignOut';
import './SiteContainer.scss';

export function SiteContainer() {
    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated();
    const logoSrc = `${process.env.REACT_APP_STORAGE_ACCOUNT_URL}/logo_small.png${process.env.REACT_APP_STORAGE_ACCOUNT_ASSETS_SAS}`;

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/', { replace: true });
        }
    }, [isAuthenticated]);

    return (
        <div className="is-flex direction-column">
            <nav className="navbar">
                <ul className="navbar-items is-flex justify-start">
                    <li className="nav-brand">
                        <NavLink to="/home/dashboard">
                            <img className="logo" src={logoSrc} alt="Kegsplitter logo" />
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="btn btn-nav-link" to="/home/dashboard">
                            Dashboard
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="btn btn-nav-link" to="/home/invoices">
                            Invoices
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <SignOutButton className="btn btn-nav-link">Log out</SignOutButton>
                    </li>
                </ul>
            </nav>
            <Outlet />
        </div>
    );
}
