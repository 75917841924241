import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useApiWithAccessToken } from '../../hooks/useApiWithAccessToken';
import { Keg } from '../../models/keg';
import { Person } from '../../models/person';
import { KegPanel, KegPanelInactive } from '../KegPanel/KegPanel';
import './Dashboard.scss';

export function Dashboard() {
    const { getKegs, getPerson, getAverageVolumeError } = useApiWithAccessToken();
    const [kegs, setKegs] = useState<Keg[]>([]);
    const [volumeError, setVolumeError] = useState<number>(0);
    const [person, setPerson] = useState<Person>();
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);

            await Promise.all([getKegs(), getPerson(), getAverageVolumeError()]).then((values) => {
                const [kegs, person, volumeError] = values;
                setKegs(kegs);
                setPerson(person);
                setVolumeError(volumeError);
                setIsLoading(false);
            });
        }

        fetchData().catch((e) => console.error(e));
    }, []);

    function openModal() {
        setShowModal(true);
    }

    function closeModal() {
        setShowModal(false);
    }

    return (
        <div className="dashboard-container is-flex direction-column">
            {isLoading ? (
                <div className="loading is-flex align-items-center justify-center">
                    <h1>Loading</h1>
                </div>
            ) : (
                <div className="dashboard-content">
                    <h1>Currently serving</h1>
                    <h2>{person?.name}</h2>
                    <div className="grid col-2">
                        {kegs
                            .filter((keg) => keg.active)
                            .map((keg) => (
                                <div className="grid-item" key={keg.externalIdentifier}>
                                    <KegPanel
                                        content={keg}
                                        person={person}
                                        volumeError={volumeError}
                                        onSubmit={openModal}
                                    />
                                </div>
                            ))}
                    </div>
                    <h1>Past kegs</h1>
                    <div className="grid col-2">
                        {kegs
                            .filter((keg) => !keg.active)
                            .map((keg) => (
                                <div className="grid-item" key={keg.externalIdentifier}>
                                    <KegPanelInactive content={keg} />
                                </div>
                            ))}
                    </div>
                </div>
            )}
            <SubmittedModal showModal={showModal} onCloseModal={closeModal} />
        </div>
    );
}

function SubmittedModal({ showModal, onCloseModal }: { showModal: boolean; onCloseModal: () => void }) {
    return (
        <Modal className="submitted-modal" isOpen={showModal} contentLabel="Minimal Modal Example">
            <div className="modal-content is-flex direction-column align-items-center">
                <h1>Drink recorded</h1>
                <button className="btn btn-primary" onClick={onCloseModal}>
                    Close
                </button>
            </div>
        </Modal>
    );
}
