import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { tokenRequest } from '../../msalConfig/authConfig';
import { SignInButton } from '../Authentication/SignIn';
import './LoginPage.scss';

export function LoginPage() {
    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const [accessToken, setAccessToken] = useState<string | undefined>(undefined);

    useEffect(() => {
        function requestAccessToken() {
            const request = {
                ...tokenRequest,
                account: accounts[0],
            };

            // Silently acquires an access token which is then attached to a request for Microsoft Graph data
            instance
                .acquireTokenSilent(request)
                .then((response) => {
                    setAccessToken(response.accessToken);
                })
                .catch(() => {
                    instance.acquireTokenPopup(request).then((response) => {
                        setAccessToken(response.accessToken);
                    });
                });
        }

        if (isAuthenticated) {
            requestAccessToken();
        }
    }, [accounts, instance, isAuthenticated]);

    useEffect(() => {
        if (accessToken) {
            navigate('home/dashboard', { replace: true });
        }
    }, [accessToken]);

    const backgroundVideoSrc = `${process.env.REACT_APP_STORAGE_ACCOUNT_URL}/beer-background-hq.webm${process.env.REACT_APP_STORAGE_ACCOUNT_ASSETS_SAS}`;
    const backgroundPosterSrc = `${process.env.REACT_APP_STORAGE_ACCOUNT_URL}/beer-background.jpg${process.env.REACT_APP_STORAGE_ACCOUNT_ASSETS_SAS}`;

    return (
        <div className="page-container is-flex align-items-center justify-center">
            <div className="login-container is-flex direction-column align-items-center justify-center">
                <div className="content-container align-items-center">
                    {isAuthenticated ? <AuthenticatedLayout /> : <UnauthenticatedLayout />}
                </div>
            </div>

            <video className="video-background" poster={backgroundPosterSrc} autoPlay muted loop>
                <source src={backgroundVideoSrc} type="video/webm" />
            </video>
        </div>
    );
}

function UnauthenticatedLayout() {
    return (
        <>
            <h1 className="login-header">Kegsplitter</h1>
            <SignInButton className="btn btn-primary">Login</SignInButton>;
        </>
    );
}

function AuthenticatedLayout() {
    return (
        <>
            <h1 className="login-header">Logging you in, please wait...</h1>
        </>
    );
}
