/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 * For more details on using MSAL.js with Azure AD B2C, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/working-with-b2c.md
 */

const clientId = process.env.REACT_APP_B2C_CLIENTID || '';
const redirectUri = process.env.REACT_APP_B2C_REDIRECT_URI || '';

export const msalConfig = {
    auth: {
        clientId, // This is the ONLY mandatory field; everything else is optional.
        authority: 'https://kegsplitterb2c.b2clogin.com/kegsplitterb2c.onmicrosoft.com/B2C_1_signupsignin', // Choose sign-up/sign-in user-flow as your default.
        knownAuthorities: ['kegsplitterb2c.b2clogin.com'], // You must identify your tenant's domain as a known authority.
        redirectUri, // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
    },
    cache: {
        cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
    },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: ['openid'],
};

/**
 * Scopes you add here will be used to request a token from Azure AD B2C to be used for accessing a protected resource.
 * To learn more about how to work with scopes and resources, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const tokenRequest = {
    scopes: [
        'https://kegsplitterb2c.onmicrosoft.com/kegsplitterapi/kegsplitter.read',
        'https://kegsplitterb2c.onmicrosoft.com/kegsplitterapi/kegsplitter.write',
    ], // e.g. ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"]
    forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
};
