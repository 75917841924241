import React from 'react';
import ReactDOM from 'react-dom/client';
import Modal from 'react-modal';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './msalConfig/authConfig';
import { Admin } from './components/Admin/Admin';
import { Dashboard } from './components/Dashboard/Dashboard';
import { Invoices } from './components/Invoices/Invoices';
import { LoginPage } from './components/LoginPage/LoginPage';
import { SiteContainer } from './components/SiteContainer/SiteContainer';
import './index.scss';

Modal.setAppElement('#root');
const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<LoginPage />} />
                    <Route path="home" element={<SiteContainer />}>
                        <Route path="dashboard" element={<Dashboard />} />
                        <Route path="invoices" element={<Invoices />} />
                        <Route path="admin" element={<Admin />} />
                        <Route path="*" element={<Navigate to="/home/dashboard" replace />} />
                    </Route>
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
            </BrowserRouter>
        </MsalProvider>
    </React.StrictMode>,
);
