import { parseISO } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import { useEffect, useState } from 'react';
import { useApiWithAccessToken } from '../../hooks/useApiWithAccessToken';
import { Glass } from '../../models/glass';
import { Keg } from '../../models/keg';
import { KegParticipation } from '../../models/keg-participation';
import { KegStatus } from '../../models/keg-status';
import { Person } from '../../models/person';
import { Portion } from '../../models/portion';
import './KegPanel.scss';
import IconQuestionCircle from '../Icons/IconQuestionCircle';
import { Tooltip } from 'react-tooltip';

export function KegPanelInactive({ content }: { content: Keg }) {
    const { getKegStatus } = useApiWithAccessToken();
    const [kegStatus, setKegStatus] = useState<KegStatus>();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);

            await getKegStatus(content.externalIdentifier).then((kegStatus) => {
                setKegStatus(kegStatus);
                setIsLoading(false);
            });
        }

        fetchData().catch((e) => console.error(e));
    }, [content]);

    return (
        <div className="keg-panel is-flex direction-column">
            {isLoading && (
                <div className="loading is-flex align-items-center justify-center">
                    <h1>Loading</h1>
                </div>
            )}
            <div className="panel-header">
                <h2 className="header">{content.product.name}</h2>
            </div>
            <div className="panel-content">
                <div className="description-container">
                    <h3 className="header">{content.name}</h3>
                    <p className="ingress">{content.description}</p>
                    <a className="link" href={content.product.productUrl}>
                        Product information <ExternalLinkSvg />
                    </a>
                </div>

                <div className="status-container">
                    <p>
                        Remaining volume is <b>{kegStatus?.currentVolume}</b> out of <b>{kegStatus?.totalVolume}</b>{' '}
                        liters.
                    </p>
                </div>
            </div>
        </div>
    );
}

function convertUtcTimestampToLocalTime(timestamp: string): string {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const zonedDate = utcToZonedTime(parseISO(timestamp), timeZone);

    return format(zonedDate, 'dd.MM.yyyy HH.mm.ss', {
        timeZone,
    });
}

export function KegPanel({
    content,
    person,
    volumeError,
    onSubmit,
}: {
    content: Keg;
    person?: Person;
    volumeError: number;
    onSubmit: () => void;
}) {
    const { submitGlass, getKegStatus, getParticipations, removeGlass } = useApiWithAccessToken();
    const [kegStatus, setKegStatus] = useState<KegStatus>();
    const [kegParticipations, setKegParticipations] = useState<KegParticipation>();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);

            await Promise.all([
                getKegStatus(content.externalIdentifier),
                getParticipations(content.externalIdentifier),
            ]).then((values) => {
                const [kegStatus, participations] = values;
                setKegStatus(kegStatus);
                setKegParticipations(participations);
                setIsLoading(false);
            });
        }

        fetchData().catch((e) => console.error(e));
    }, [content.externalIdentifier]);

    async function handlePintSubmit(size: number): Promise<void> {
        const glass = new Glass({
            externalPersonIdentifier: person?.externalIdentifier,
            externalKegIdentifier: content.externalIdentifier,
            volume: size,
        });

        setIsLoading(true);

        await submitGlass(glass)
            .then(() =>
                Promise.all([getKegStatus(content.externalIdentifier), getParticipations(content.externalIdentifier)]),
            )
            .then((values) => {
                const [kegStatus, participations] = values;
                setKegStatus(kegStatus);
                setKegParticipations(participations);
                setIsLoading(false);
            })
            .catch((error) => console.error(error));

        onSubmit();
    }

    async function handleGlassDelete(glassId: string): Promise<void> {
        setIsLoading(true);

        await removeGlass(glassId)
            .then(() =>
                Promise.all([getKegStatus(content.externalIdentifier), getParticipations(content.externalIdentifier)]),
            )
            .then((values) => {
                const [kegStatus, participations] = values;
                setKegStatus(kegStatus);
                setKegParticipations(participations);
                setIsLoading(false);
            })
            .catch((error) => console.error(error));
    }

    return (
        <div className="keg-panel is-flex direction-column">
            {isLoading && (
                <div className="loading is-flex align-items-center justify-center">
                    <h1>Loading</h1>
                </div>
            )}
            <div className="panel-header">
                <h2 className="header">{content.product.name}</h2>
            </div>
            <div className="panel-content">
                <div className="description-container">
                    <h3 className="header">{content.name}</h3>
                    <p className="ingress">{content.description}</p>
                    <a className="link" href={content.product.productUrl}>
                        Product information <ExternalLinkSvg />
                    </a>
                </div>
                <div className="status-container">
                    <p>
                        Remaining volume is <b>{kegStatus?.currentVolume}</b> out of reported volume{' '}
                        <b>{kegStatus?.totalVolume}</b> liters. <IconQuestionCircle id="volume-error-element" />
                    </p>
                    <Tooltip anchorSelect="#volume-error-element">
                        Average volume error is <b>{volumeError}</b> liters.
                    </Tooltip>
                </div>
                <hr />
                <fieldset className="button-container" disabled={isLoading}>
                    <button className="btn btn-primary" onClick={() => handlePintSubmit(0.5)}>
                        Regular (0,50l)
                    </button>
                    <button className="btn btn-primary" onClick={() => handlePintSubmit(0.4)}>
                        Medium (0,40l)
                    </button>
                    <button className="btn btn-primary" onClick={() => handlePintSubmit(0.33)}>
                        Small (0,33l)
                    </button>
                    <button className="btn btn-primary" onClick={() => handlePintSubmit(0.25)}>
                        Baby size (0,25l)
                    </button>
                </fieldset>
                {kegParticipations && (
                    <div className="history-container">
                        <h2 className="history-header">History</h2>
                        <p>Showing five latest pints</p>
                        <div className="history-content">
                            <ul className="history-list">
                                {kegParticipations.portions.slice(0, 5).map((p: Portion) => (
                                    <li className="history-item is-flex" key={p.externalGlassIdentifier}>
                                        <div className="is-flex direction-column">
                                            <h3 className="history-item-header">
                                                {convertUtcTimestampToLocalTime(p.timestamp)}
                                            </h3>
                                            <p className="history-item-content">{p.volume} liters</p>
                                        </div>
                                        <button
                                            className="history-item-delete-button btn btn-primary"
                                            onClick={() => handleGlassDelete(p.externalGlassIdentifier)}
                                        >
                                            Delete
                                        </button>
                                    </li>
                                ))}
                            </ul>
                            <p>
                                <b>Total of {kegParticipations.totalVolume} liters</b>
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

function ExternalLinkSvg() {
    // Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc.
    return (
        <svg className="external-link" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path d="M256 64C256 46.33 270.3 32 288 32H415.1C415.1 32 415.1 32 415.1 32C420.3 32 424.5 32.86 428.2 34.43C431.1 35.98 435.5 38.27 438.6 41.3C438.6 41.35 438.6 41.4 438.7 41.44C444.9 47.66 447.1 55.78 448 63.9C448 63.94 448 63.97 448 64V192C448 209.7 433.7 224 416 224C398.3 224 384 209.7 384 192V141.3L214.6 310.6C202.1 323.1 181.9 323.1 169.4 310.6C156.9 298.1 156.9 277.9 169.4 265.4L338.7 96H288C270.3 96 256 81.67 256 64V64zM0 128C0 92.65 28.65 64 64 64H160C177.7 64 192 78.33 192 96C192 113.7 177.7 128 160 128H64V416H352V320C352 302.3 366.3 288 384 288C401.7 288 416 302.3 416 320V416C416 451.3 387.3 480 352 480H64C28.65 480 0 451.3 0 416V128z" />
        </svg>
    );
}
