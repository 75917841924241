export class Glass {
    constructor(init?: Partial<Glass>) {
        Object.assign(this, init);
    }

    public externalIdentifier = '';
    public volume = 0;
    public timestamp = new Date();
    public externalKegIdentifier = '';
    public externalPersonIdentifier = '';
}
