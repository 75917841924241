import { ChangeEvent, useEffect, useState } from 'react';
import { useApiWithAccessToken } from '../../hooks/useApiWithAccessToken';
import { Invoice } from '../../models/invoice';
import { Keg } from '../../models/keg';
import './Invoices.scss';

export function Invoices() {
    const { getKegs, getInvoiceWithParticipationBasedSplit } = useApiWithAccessToken();
    const [kegs, setKegs] = useState<Keg[]>([]);
    const [invoice, setInvoice] = useState<Invoice>();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            const kegs = await getKegs();
            setKegs(kegs);
            setIsLoading(false);
        }

        fetchData().catch((e) => console.error(e));
    }, []);

    async function onKegSelected(event: ChangeEvent<HTMLSelectElement>) {
        const value = event.target.value;

        if (value) {
            const invoice = await getInvoiceWithParticipationBasedSplit(value);
            setInvoice(invoice);
        }
    }

    return (
        <div className="invoices-container is-flex direction-column">
            {isLoading ? (
                <div className="loading is-flex align-items-center justify-center">
                    <h1>Loading</h1>
                </div>
            ) : (
                <div className="invoices-content">
                    <h1>Invoices</h1>
                    <div className="form-group">
                        <label className="form-label is-flex direction-column" htmlFor="keg-select">
                            <span className="label-text">Invoiced keg</span>
                            <select name="keg-select" className="form-select" onChange={onKegSelected}>
                                <option key="undefined" value="">
                                    -
                                </option>
                                {kegs.map((k) => (
                                    <option key={k.externalIdentifier} value={k.externalIdentifier}>
                                        {k.name}
                                    </option>
                                ))}
                            </select>
                        </label>
                    </div>
                    {invoice && <InvoicePanel invoice={invoice} />}
                </div>
            )}
        </div>
    );
}

function InvoicePanel({ invoice }: { invoice: Invoice }) {
    return (
        <div className="invoice-panel-container">
            <div className="invoice-panel-header">
                <h2 className="header">Invoice</h2>
            </div>
            <div className="invoice-panel-content">
                <div className="description-container">
                    <p>{invoice.description}</p>
                    <p>
                        <b>Total volume:</b> {invoice.volume}
                    </p>
                    <p>
                        <b>Total price:</b> {invoice.price}
                    </p>
                    <p>
                        <b>Price per liter:</b> {invoice.pricePerLiter.toFixed(2)}
                    </p>
                </div>
                <h2>Participants</h2>
                <table className="invoice-table">
                    <thead>
                        <tr>
                            <th className="table-column">Person name</th>
                            <th className="table-column">Consumed volume (liter)</th>
                            <th className="table-column">Price (€)</th>
                            <th className="table-column">Split leftover (€)</th>
                            <th className="table-column">Total price (€)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoice.invoiceRows?.map((row, index) => (
                            <tr className="table-row" key={index}>
                                <td className="table-column">{row.personName}</td>
                                <td className="table-column">{row.totalVolume.toFixed(2)}</td>
                                <td className="table-column">{row.totalPrice.toFixed(2)}</td>
                                <td className="table-column">{row.leftoverPrice.toFixed(2)}</td>
                                <td className="table-column">{(row.totalPrice + row.leftoverPrice).toFixed(2)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
