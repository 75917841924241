import { IPublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { ReactNode } from 'react';

function handleLogin(instance: IPublicClientApplication) {
    instance.logoutRedirect().catch((e: Error) => {
        console.error(e);
    });
}

/**
 * Renders a button which, when selected, will redirect the page to the logout prompt
 */
export function SignOutButton({ className, children }: { className: string; children: ReactNode }) {
    const { instance } = useMsal();

    return (
        <button className={className} onClick={() => handleLogin(instance)}>
            {children}
        </button>
    );
}
