import { IPublicClientApplication, AccountInfo } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { Glass } from '../models/glass';
import { Invoice } from '../models/invoice';
import { Keg } from '../models/keg';
import { KegParticipation } from '../models/keg-participation';
import { KegStatus } from '../models/keg-status';
import { Person } from '../models/person';
import { tokenRequest } from '../msalConfig/authConfig';

export function useApiWithAccessToken() {
    const { instance, accounts } = useMsal();

    async function getKegs(): Promise<Keg[]> {
        const uri = `${process.env.REACT_APP_API_BASEURL}/api/getkegs`;
        return await getWithAccessToken<Keg[]>(instance, accounts[0], uri);
    }

    async function getKegStatus(kegId: string): Promise<KegStatus> {
        const uri = `${process.env.REACT_APP_API_BASEURL}/api/getkegstatus?id=${kegId}`;
        return await getWithAccessToken<KegStatus>(instance, accounts[0], uri);
    }

    async function getAverageVolumeError(): Promise<number> {
        const uri = `${process.env.REACT_APP_API_BASEURL}/api/kegs/stats/average-error`;
        return await getWithAccessToken<number>(instance, accounts[0], uri);
    }

    async function submitGlass(glass: Glass): Promise<string> {
        const uri = `${process.env.REACT_APP_API_BASEURL}/api/createglass`;
        return await postWithAccessToken<Glass>(instance, accounts[0], uri, glass);
    }

    async function getPerson(): Promise<Person> {
        const uri = `${process.env.REACT_APP_API_BASEURL}/api/getperson?useToken=true`;
        return await getWithAccessToken<Person>(instance, accounts[0], uri);
    }

    async function getInvoiceByKegId(kegId: string): Promise<Invoice> {
        const uri = `${process.env.REACT_APP_API_BASEURL}/api/invoice/${kegId}`;
        return await getWithAccessToken<Invoice>(instance, accounts[0], uri);
    }

    async function getParticipations(kegId: string): Promise<KegParticipation> {
        const uri = `${process.env.REACT_APP_API_BASEURL}/api/kegs/${kegId}/participations`;
        return await getWithAccessToken<KegParticipation>(instance, accounts[0], uri);
    }

    async function getInvoiceWithParticipationBasedSplit(kegId: string): Promise<Invoice> {
        const uri = `${process.env.REACT_APP_API_BASEURL}/api/invoice/${kegId}/participation-split`;
        return await getWithAccessToken<Invoice>(instance, accounts[0], uri);
    }

    async function removeGlass(glassId: string): Promise<void> {
        const uri = `${process.env.REACT_APP_API_BASEURL}/api/removeglass?id=${glassId}`;
        return await deleteWithAccessToken(instance, accounts[0], uri);
    }

    return {
        getKegs,
        submitGlass,
        getPerson,
        getInvoiceByKegId,
        getInvoiceWithParticipationBasedSplit,
        getKegStatus,
        getParticipations,
        removeGlass,
        getAverageVolumeError,
    };
}

async function getWithAccessToken<T>(
    msalInstance: IPublicClientApplication,
    account: AccountInfo,
    uri: string,
): Promise<T> {
    const request = {
        ...tokenRequest,
        account,
    };

    return await msalInstance.acquireTokenSilent(request).then(async (response) => {
        const headers = new Headers();
        const bearer = `Bearer ${response.accessToken}`;
        headers.append('Authorization', bearer);

        const options: RequestInit = {
            method: 'GET',
            headers: headers,
        };

        return await fetch(uri, options).then((response) => response.json());
    });
}

async function deleteWithAccessToken(
    msalInstance: IPublicClientApplication,
    account: AccountInfo,
    uri: string,
): Promise<void> {
    const request = {
        ...tokenRequest,
        account,
    };

    return await msalInstance.acquireTokenSilent(request).then(async (response) => {
        const headers = new Headers();
        const bearer = `Bearer ${response.accessToken}`;
        headers.append('Authorization', bearer);

        const options: RequestInit = {
            method: 'DELETE',
            headers: headers,
        };

        return await fetch(uri, options).then();
    });
}

async function postWithAccessToken<T>(
    msalInstance: IPublicClientApplication,
    account: AccountInfo,
    uri: string,
    payload: T,
): Promise<string> {
    const request = {
        ...tokenRequest,
        account,
    };

    return await msalInstance.acquireTokenSilent(request).then(async (response) => {
        const headers = new Headers();
        const bearer = `Bearer ${response.accessToken}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');

        const options: RequestInit = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(payload),
        };

        return await fetch(uri, options).then((response) => response.json());
    });
}
